<template>
  <div class="SetupName">
    <input
      class="NameDescription"
      v-model="name"
      placeholder="Название тестирования"
      @change="sendDataToParent('name', name)"
      type="text"
    />
    <input
      class="NameDescription"
      v-model="description"
      placeholder="Описание"
      @change="sendDataToParent('description', description)"
      type="text"
    />
  </div>
</template>
<script>
export default {
  props: {
    sendDataToParent: {
      type: Function,
      default: () => {
        return () => {};
      },
    },
    startData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      name: "",
      description: "",
    };
  },
  mounted() {
    if (this.startData != null) {
      if (this.startData?.name != null) {
        this.name = this.startData?.name;
      }
      if (this.startData?.description != null) {
        this.description = this.startData?.description;
      }
    }
  },
};
</script>

<style scoped>
.SetupName {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: rgba(31, 30, 72, 0.8);
  border-radius: 20px;
  width: auto;
  height: fit-content;
  position: relative;
  overflow: hidden;
  padding: 30px 30px 15px 30px;
}
.NameDescription {
  display: flex;
  background: var(
    --input,
    linear-gradient(
      269deg,
      rgba(41, 37, 88, 0.54) 0%,
      rgba(38, 35, 83, 0.87) 40%,
      rgba(40, 38, 89, 0.5) 100%
    )
  );
  border-radius: 4px;
  border-style: solid;
  border-color: #413e74;
  border-width: 1px;
  padding: 13px 0px 13px 30px;
  width: auto;
  height: 48px;
  margin-bottom: 15px;

  color: #ffffff;
  font-family: "Inter-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.NameDescription::placeholder {
  color: #667080;
}
@media (max-width: 479px) {
  .NameDescription {
    font-size: 13px;
    padding: 13px;
  }
  .SetupName {
    padding: 20px;
  }
}
</style>
