<template>
  <div id="TestPage">
    <div class="HiddentProjectsList">
      <div class="ProjectListHeader">
        <h1>
          {{ PageMode == "add" ? "Создание тестов" : "Редактирование тестов" }}
        </h1>
      </div>

      <div class="SetupBlock BlockColumn1">
        <div class="SetupTest">
          <div class="LeftCol">
            <span class="ParamsTest">Параметры теста</span>
            <div class="SetupTime">
              <div class="PointsBlock">
                <span class="SetupTextPoint">Проходной балл</span>
                <input
                  class="TestName"
                  v-model="quiz.success_limit"
                  type="number"
                  @input="handleInput"
                  @change="handleInput"
                />
                <span class="SetupTextPoint"> %</span>
              </div>
              <div class="TimerBlock">
                <span class="SetupTextPoint">Время тестирования</span>
                <input
                  class="TestName"
                  v-model="quiz.duration_time"
                  type="number"
                  @input="handleTimeInput"
                  @change="handleTimeInput"
                />
                <span class="SetupTextPoint">минут</span>
              </div>
            </div>
            <div class="SetupDirection">
              <div class="SetupCheck">
                <label class="SetupCheckText">
                  <input
                    type="checkbox"
                    v-model="quiz.randomize"
                    class="real-checkbox"
                  />
                  <span class="custom-checkbox"></span>
                  <span class="SetupTextPoint"
                    >Вопросы выпадают в рандомном порядке</span
                  >
                </label>
                <div class="ModalCompetence">
                  <div class="Competence">
                    <span class="Competence-text">Компетенция</span>
                    <svg
                      @click="
                        showModal ? (showModal = false) : (showModal = true)
                      "
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <line
                        y1="-0.5"
                        x2="8.47229"
                        y2="-0.5"
                        transform="matrix(0.706021 0.708191 -0.706021 0.708191 2 5)"
                        stroke="white"
                      />
                      <line
                        y1="-0.5"
                        x2="8.47229"
                        y2="-0.5"
                        transform="matrix(-0.706021 0.708191 0.706021 0.708191 14 5)"
                        stroke="white"
                      />
                    </svg>
                    <!-- <img
                      @click="
                        showModal ? (showModal = false) : (showModal = true)
                      "
                      src="#"
                      alt="v"
                    /> -->
                  </div>

                  <!--       <div v-for="direction in selectedDirections" :key="direction">
                
                  <div>{{ direction }}</div>
                  </div>  -->
                  <!-- Здесь код для отображения блока с выбранным направлением -->
                  <!-- выпадающая модалка -->
                  <div class="filters" v-if="showModal">
                    <span class="filters-title">Направления</span>
                    <div class="filters-items">
                      <span
                        v-for="(Direction, indx) in SectionsList"
                        :key="`${indx}${Direction.id}`"
                        @click="quiz.competence_id = Direction.id"
                        class="request-status blue-color"
                        :class="{
                          'blue-color-solid':
                            quiz.competence_id == Direction.id,
                        }"
                        >{{ Direction.name }}</span
                      >

                      <!-- <img src="@/assets/img/setup/expand.svg" alt="v" /> -->
                    </div>
                    <div class="PrevBackButtons">
                      <ButtonGradient
                        @click="showModal = false"
                        class="ButtonBorder"
                        ButtonText="Отменить"
                      />
                      <ButtonGradient
                        @click="moveElement"
                        class="ButtonBorder"
                        ButtonText="Сохранить"
                      />
                    </div>
                  </div>
                  <div class="competencies-items">
                    <span
                      v-for="(item, index) in competencies"
                      :key="index"
                      @click="updFilters($event)"
                      class="request-status"
                      :class="{
                        'green-color-solid': item === 'Web-разработка',
                        'violet-color-solid': item === 'Аналитика',
                        'yellow-color-solid': item === '1C',
                        'blue-color-solid': item === 'Дизайн',
                      }"
                    >
                      {{ item }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="SetupCheck">
                <label class="SetupCheckText">
                  <input
                    type="checkbox"
                    v-model="quiz.previous"
                    class="real-checkbox"
                  />
                  <span class="custom-checkbox"></span>
                  <span class="SetupTextPoint"
                    >Можно возвращаться к предыдущему</span
                  >
                </label>
                <span> </span>
              </div>
            </div>
          </div>

          <div class="RightButtons">
            <ButtonGradientBorder
              class="ButtonColor"
              :ButtonText="
                PageMode == 'add' ? 'Опубликовать тест' : 'Сохранить изменения'
              "
              @click.native="PageMode == 'add' ? addQuiz() : updQuiz()"
            />
            <!-- <ButtonGradientBorder class="MobileBtn" ButtonText="Опубликовать" /> -->
            <ButtonGradient
              v-if="PageMode == 'edit'"
              class="ButtonBorderEnd"
              ButtonText="Удалить"
              @click.native="delQuiz"
            />
          </div>
        </div>

        <TestName
          ref="QuizNameDesc"
          :key="quiz_edit_startData"
          :startData="quiz_edit_startData"
          :sendDataToParent="setDataFromTestName"
        />

        <QuestionSetup
          v-for="index in questions.length"
          :key="questions[index - 1]"
          :ref="`QuizQuestionBlock${index - 1}`"
          :questionData="questions[index - 1]"
          :questionSetupIndex="index - 1"
          :removeQuestionSetup="removeQuestion"
        />

        <ButtonGradientBorder
          v-if="PageMode == 'add'"
          class="Button"
          @click="addQuestion"
          ButtonText="Добавить новый вопрос"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TestName from "@/components/TestingUnit/TestName";
import QuestionSetup from "@/components/TestingUnit/QuestionSetup";
import ButtonGradient from "@/components/CustomElements/ButtonGradient.vue";
import ButtonGradientBorder from "@/components/CustomElements/ButtonGradientBorder.vue";

import { mapGetters } from "vuex";
import { getSections } from "@/api/competence.js";

import {
  createQuiz,
  createQuizQuestion,
  createQuizQuestionAnswer,
  setQuestionToQuiz,
  setQuizQuestionOrder,
  getQuizByID,
  deleteQuiz,
  updateQuizData,
} from "@/api/user_tests.js";

export default {
  components: {
    QuestionSetup,
    ButtonGradientBorder,
    ButtonGradient,
    TestName,
  },
  data() {
    return {
      quiz: {
        name: "",
        description: "",
        success_limit: 0,
        randomize: false,
        previous: false,
        duration_time: 0,
        creator_id: null,
        competence_id: null,
      },

      questions: [null],
      showModal: false,
      filters: [],
      competencies: null, // новый массив для компетенций

      SectionsList: [],

      PageMode: "add",
      quiz_edit_id: "",
      quiz_edit_startData: null,
      quiz_questions: [],
    };
  },
  watch: {
    user: {
      handler(val) {
        if (this.id) {
          this.id = this.user.id;
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),

    getUser() {
      return this?.user;
    },
  },
  methods: {
    addQuestion() {
      this.questions.push(null);
    },
    updFilters(event) {
      const status = event.target.innerText;
      if (this.filters.includes(status)) {
        this.filters = this.filters.filter((filter) => filter !== status);
      } else {
        this.filters.push(status);
      }
    },
    moveElement() {
      this.competencies = [...this.filters];
      this.filters = [];
      this.showModal = false;
    },

    async addQuiz() {
      this.quiz.creator_id = this.getUser?.id;
      let _createdQuiz = await createQuiz(this.quiz);
      let QuizID = _createdQuiz.data[0].id;

      for (let i = 0; i < this.questions.length; i++) {
        let question = this.$refs[`QuizQuestionBlock${i}`][0].$data.question;
        let question_data = {
          title: question.name,
          description: "Не указано",
          type_id: question.type.id,
        };
        let _question_resp = await createQuizQuestion([question_data]);

        for (let i of question.answers) {
          let answer_data = {
            answer: i.text,
            correct: Number(i.isRightQuestion),
            question_id: _question_resp.data[0].id,
          };
          await createQuizQuestionAnswer(answer_data);
        }

        let _quizQuestion_resp = await setQuestionToQuiz({
          quiz_id: QuizID,
          question_id: _question_resp.data[0].id,
        });

        await setQuizQuestionOrder({
          quiz_question_id: _quizQuestion_resp.data[0].id,
          order_number: i,
        });
      }

      window.location.replace("/admin/tests/manage");
    },

    async updQuiz() {
      let quizData = {
        id: this.quiz_edit_id,
        name: this.quiz.name,
        description: this.quiz.description,
        success_limit: this.quiz.success_limit,
        randomize: this.quiz.randomize,
        previous: this.quiz.previous,
        duration_time: this.quiz.duration_time,
        competence_id: this.quiz.competence_id,
      };
      let answersData = [];
      let questionsData = [];

      for (let i = 0; i < this.questions.length; i++) {
        let question = this.$refs[`QuizQuestionBlock${i}`][0].$data.question;
        questionsData.push({
          id: question.id,
          title: question.name,
        });

        for (let i of question.answers) {
          answersData.push({
            id: i.id,
            answer: i.text,
            correct: i.isRightQuestion,
          });
        }
      }

      await updateQuizData({
        QuizData: quizData,
        QuestionsData: questionsData,
        AnswersData: answersData,
      });

      window.location.replace(`/admin/test/${this.quiz_edit_id}/edit`);
    },

    setDataFromTestName(field, value) {
      this.quiz[field] = value;
    },

    async delQuiz() {
      await deleteQuiz({ id: this.quiz_edit_id });
      window.location.replace("/admin/test/add");
    },

    removeQuestion(index) {
      if (index == null || index == 0) {
        return 0;
      }

      this.questions.splice(index, 1);
    },

    handleInput(event) {
      // Получаем введенное значение
      let value = event.target.value;

      // Фильтруем вводимые символы, оставляя только цифры
      value = parseInt(value.replace(/[^\d]/g, ""));

      // Проверяем, что значение находится в диапазоне от 0 до 100
      if (value > 100) {
        value = 100;
      } else if (value < 1) {
        value = 1;
      }

      if (value == null || value == "") {
        value = 1;
      }

      // Обновляем значение в модели
      this.quiz.success_limit = `${value}`.replace(/^0+/, "");
    },
    handleTimeInput(event) {
      // Получаем введенное значение
      let value = event.target.value;

      // Фильтруем вводимые символы, оставляя только цифры
      value = parseInt(value.replace(/[^\d]/g, ""));

      // Проверяем, что значение находится в диапазоне от 0 до 100
      if (value > 1440) {
        value = 1440;
      } else if (value < 1) {
        value = 1;
      }

      if (value == null || value == "") {
        value = 1;
      }

      this.quiz.duration_time = `${value}`.replace(/^0+/, "");
    },
  },
  async created() {
    if (this.$route.params?.test_id != null) {
      this.PageMode = "edit";
      this.quiz_edit_id = this.$route.params.test_id;

      let _quiz_resp = await getQuizByID(this.quiz_edit_id);
      if (_quiz_resp.data?.status && _quiz_resp.data?.status == "fail") {
        window.location.replace("/");
      }

      for (let prop in this.quiz) {
        this.quiz[prop] = _quiz_resp.data[prop];
      }

      this.quiz_edit_startData = {
        name: this.quiz.name,
        description: this.quiz.description,
      };

      this.questions = [];
      for (let i = 0; i < _quiz_resp.data.QQuiz.length; i++) {
        console.log(_quiz_resp.data.QQuiz[i].Question_QQuestion.TQuestion);
        this.questions.push({
          id: _quiz_resp.data.QQuiz[i].Question_QQuestion.id,
          name: _quiz_resp.data.QQuiz[i].Question_QQuestion.title,
          type: _quiz_resp.data.QQuiz[i].Question_QQuestion.TQuestion,
          answers: _quiz_resp.data.QQuiz[i].Question_QQuestion.AQuestion.map(
            (item, indx) => {
              let container = {
                id: item.id,
                order: indx,
                text: item.answer,
                isRightQuestion: item.correct,
              };

              return container;
            }
          ),
        });
      }
    }

    getSections().then((response) => {
      let sections = response.data;
      let competence = [];

      let self = this;

      let tags = new Set(sections.map((section) => section.tag));
      tags.forEach((tag) => {
        let filteredSections = sections
          .filter((section) => section.tag === tag)
          .map((section) => {
            return {
              name: section.name,
              id: section.id,
              levels: section.degrees
                .flatMap((degree) => degree.levels.reverse())
                .reverse()
                .map((x) => x.id),
            };
          });
        competence.push({
          name: tag,
          counter: filteredSections.length,
          CBlock: filteredSections,
        });
      });

      if (competence.length >= 1) {
        self.SectionsList = competence[0].CBlock;
      }
    });
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.ParamsTest {
  display: none;
}
.competencies-items {
  display: flex;
  gap: 8px;
  align-items: center;
}
.MobileBtn {
  display: none;
  justify-content: center;

  background: linear-gradient(221.26deg, #c548ab -3.4%, #635bff 101.08%);
  width: 235px;
  height: fit-content;
}

.PointsBlock,
.TimerBlock {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
}

body {
  font-family: var(--mdb-font-roboto);
  line-height: 1.6;
  color: #4f4f4f;
  height: 100%;
  width: 100%;
  display: flex;
}

#TestPage {
  position: relative;
  width: 100%;
  min-height: 100vh;

  top: 0%;
  left: 0%;

  margin: 0%;
  padding: 0%;

  display: flex;
  flex-direction: row;

  overflow-y: scroll;
  overflow-x: hidden;

  background-image: radial-gradient(
    123.22% 129.67% at 100.89% -5.6%,
    #201d47 0%,
    #17153a 100%
  );
  background-position: 50% 50%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: auto 120%;
}

.HiddentProjectsList {
  position: relative;

  left: 0px;

  width: 80vw;
  height: auto;

  margin: 70px auto 0% auto;
  padding: 1% 3% 0% 3%;

  display: flex;
  justify-content: flex-start;

  flex-direction: column;
}

.ProjectListHeader {
  color: #ffffff;
  font-size: 32px;
  margin-bottom: 30px;
}

.BlockColumn1 {
  display: flex;
  flex-direction: column;
}

.LeftCol {
  display: flex;
  flex-direction: column;
}

.RightButtons {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 15px;
  margin-bottom: 18px;
}

.ButtonColor {
  display: flex;
  justify-content: center;

  background: linear-gradient(221.26deg, #c548ab -3.4%, #635bff 101.08%);
  width: 235px;
  height: fit-content;
}

.ButtonBorderEnd {
  display: flex;
  width: auto;
  justify-content: center;
  align-items: center;
}

.ButtonBorderEnd::before {
  background: linear-gradient(315deg, #ff7676 0%, #f54ea2 100%);
}

.SetupBlock {
  display: flex;
  gap: 20px;
}
</style>

<style scoped>
/*Стили для первого блока с настройкой теста*/
.SetupTest {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  background: rgba(31, 30, 72, 0.8);
  border-radius: 20px;
  width: auto;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
  overflow: visible;
  padding: 30px;
  gap: 32px;
  justify-content: space-between;
}

.SetupTime {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  gap: 15px;
}

.SetupTextPoint {
  color: #ffffff;
  font-size: 16px;
}

.SetupDirection {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-top: 20px;
}

/*Чекбокс*/

.SetupCheck {
  display: flex;
  flex-direction: row;
  color: #ffffff;
  gap: 20px;
}

.SetupCheckText {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.real-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: absolute;
}

.custom-checkbox {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  background: #f8f7f700;
  border: 1px solid #312e57;
  cursor: pointer;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

/*Состояния чекбокса*/

.real-checkbox:checked + .custom-checkbox {
  background-image: url("./../../assets/img/Setup/vector.svg");
  background-position: center;
}

/*Выбор направления*/
.Competence {
  font-size: 15px;
}

.Competence-text {
  margin-right: 5px;
}

.TestName {
  display: flex;
  background: var(
    --input,
    linear-gradient(
      269deg,
      rgba(41, 37, 88, 0.54) 0%,
      rgba(38, 35, 83, 0.87) 40%,
      rgba(40, 38, 89, 0.5) 100%
    )
  );
  border-radius: 4px;
  border-style: solid;
  border-color: #413e74;
  border-width: 1px;
  padding: 8px;
  width: 60px;
  height: 48px;
  top: 30px;

  color: #ffffff;
  text-align: center;
  font: 400 20px/22px "Inter", sans-serif;
  -moz-appearance: textfield;
}

.TestName::-webkit-inner-spin-button {
  display: none;
}

.TestName::placeholder {
  color: #667080;
}

/*Стили кнопок*/
.Button {
  height: auto;
  width: 100%;
}
</style>

<style scoped>
.request {
  margin-top: 0.5rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  text-align: left;

  width: 100%;
  min-height: 6rem;
  padding: 1.875rem;
  gap: 0.625rem;

  border-radius: 1.25rem;
  background: rgba(31, 30, 72, 0.8);
}

.request-btn-block {
  width: 30%;
  /* flex-basis: 375px; */
}

.request-btn {
  width: 100%;

  justify-content: center;
  flex-shrink: 0;
}

.request-info {
  width: fit-content;

  color: white;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 1;
  flex-basis: 500px;
}

.request-status {
  border: 1px solid;
  border-radius: 0.25rem;

  padding: 0.25rem 0.5rem;

  display: flex;
  align-items: flex-start;
  gap: 0.625rem;

  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.request-company-name {
  color: #fff;

  display: flex;

  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 155%;
  /* 1.45313rem */
}

.request-type {
  color: #fff;
  word-wrap: normal;

  /* h3 */
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.request-agent-name {
  color: #413e74;

  /* h3 */
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.green-color {
  color: #1dd185;
}

.yellow-color {
  color: #ffcf66;
}

.violet-color {
  color: #d434e2;
}

.blue-color {
  color: #1dc6d1;
}

.violet-color-solid {
  background-color: #d434e2;
  border-color: #d434e2;
  color: #232323;
}

.blue-color-solid {
  background-color: #1dc6d1;
  border-color: #1dc6d1;
  color: #232323;
}

.green-color-solid {
  background-color: #1dd185;
  border-color: #1dd185;
  color: #232323;
}

.yellow-color-solid {
  background-color: #ffcf66;
  color: #232323;
  border-color: #ffcf66;
}

.show-filters-btn {
  cursor: pointer;
}

.filters {
  position: absolute;

  padding: 26px 16px;
  margin-top: 0.5rem;

  left: 42%;
  max-width: 75%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;

  gap: 0.5rem;

  border-radius: 1.25rem;
  background: #1d1a42;

  box-shadow: 8px 0px 8px 0px rgba(31, 18, 35, 0.15);

  z-index: 5000;
}

.filters-title {
  overflow: hidden;
  color: #e8e7ec;
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.filters-items {
  display: flex;
  flex-wrap: wrap;

  cursor: pointer;
}

.filters-img {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.filters-items span {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.filters-btn-block {
  display: flex;
  flex-wrap: wrap;
}

.filters-btn-block div {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.PrevBackButtons {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
</style>

<style scoped>
@media (max-width: 479px) {
  #TestPage {
    position: relative;

    display: flex;
    flex-direction: column;
  }
  .ParamsTest {
    display: flex;
  }
  .competencies-items {
    display: flex;
    flex-wrap: wrap;
  }
  .SetupTime {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    height: auto;
    gap: 15px;
    align-items: flex-start;
  }

  .SetupCheck {
    display: flex;
    flex-direction: row;
    color: #ffffff;
    gap: 20px;
    flex-wrap: wrap;
  }

  .SetupDirection {
    display: flex;
    row-gap: 10px;
    margin-top: 20px;
    flex-direction: column-reverse;
  }

  .Competence-text {
    font-size: 14px;
  }
  .Competence {
    font-size: 14px;
    margin-bottom: 8px;
  }

  .filters {
    left: 0;
    width: 100%;
    max-width: 100%;
  }

  .SetupTest {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 20px;
  }

  .HiddentProjectsList {
    width: auto;
    margin: 70px 8px;
    padding: 0%;
  }

  .SetupTextPoint {
    font-size: 14px;
  }
  .MobileBtn {
    display: flex;
    width: auto;
  }
  .MobileBtn::before {
    font-size: 12px;
  }

  .ButtonColor {
    display: none;
    padding: 12px 24px;
  }
  .TestName {
    font-size: 13px;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  #TestPage {
    position: relative;

    display: flex;
    flex-direction: column;
  }
  .competencies-items {
    display: flex;
    flex-wrap: wrap;
  }
  .SetupTime {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    height: auto;
    gap: 15px;
    align-items: flex-start;
  }

  .SetupCheck {
    display: flex;
    flex-direction: row;
    color: #ffffff;
    gap: 20px;
    flex-wrap: wrap;
  }

  .SetupDirection {
    display: flex;
    row-gap: 10px;
    margin-top: 20px;
    flex-direction: column-reverse;
  }

  .Competence-text {
    font-size: 14px;
  }

  .filters {
    left: 0;
    width: 100%;
    max-width: 100%;
  }

  .SetupTest {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .HiddentProjectsList {
    width: auto;
    margin: 70px 30px;
    padding: 0%;
  }

  .ButtonColor {
    padding: 12px 24px;
  }

  .SetupTextPoint {
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #TestPage {
    position: relative;

    display: flex;
    flex-direction: column;
  }
  .competencies-items {
    display: flex;
    flex-wrap: wrap;
  }

  .HiddentProjectsList {
    width: auto;
    margin: 70px 30px;
    padding: 0%;
  }

  .ButtonColor {
    padding: 12px 24px;
  }

  .SetupTextPoint {
    font-size: 14px;
  }
  .SetupTest {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
}

@media (min-width: 992px) and (max-width: 1399px) {
  #TestPage {
    position: relative;

    display: flex;
    flex-direction: column;
  }
  .competencies-items {
    display: flex;
    flex-wrap: wrap;
  }
  .HiddentProjectsList {
    width: auto;
    margin: 70px 30px;
    padding: 0%;
  }

  .ButtonColor {
    padding: 12px 24px;
  }

  .SetupTextPoint {
    font-size: 14px;
  }
}

@media (min-width: 1400px) {
  #TestPage {
    position: relative;

    display: flex;
    flex-direction: column;
  }
  .competencies-items {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
